body {
  font-family: Arial, sans-serif;
  background-color: #f3f4f6;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 20px;
}

.container {
  background-color: #fff;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  color: #6a6f73;
  font-size: 2em;
  margin-bottom: 10px;
}

/* Estilos da Foto em Destaque */
.highlighted-photo {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.highlighted-photo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, 0.4);
  transition: background 0.3s ease;
  z-index: 0;
}

.highlighted-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: opacity 0.5s ease-in-out;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.4em;
  padding: 0 20px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

/* Estilos da Galeria de Miniaturas */
.thumbnail-gallery {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.thumbnail {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.thumbnail:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Estilos do Rodapé */
.footer {
  font-size: 0.9em;
  color: #777;
  margin-top: 20px;
}

/* Responsividade */
@media (max-width: 780px) {
  .highlighted-photo {
      height: 250px; /* Altura menor da imagem em dispositivos móveis */
  }
  h1 {
      font-size: 1.2em;
  }
  .message {
      font-size: 0.8em; /* Tamanho de fonte menor para texto em mobile */
      padding: 0 20px; /* Reduz margens laterais para dar mais espaço */
      max-width: 100%;
      width: 100%;
      box-sizing: border-box; 
  }
  .thumbnail {
      width: 50px; /* Miniaturas um pouco menores no mobile */
      height: 50px;
  }
}

/* Responsividade */
@media (max-width: 600px) {
  .container {
    padding: 10px;
    width: inherit;
  }
}
